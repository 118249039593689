import { GbBase } from "./gb-base";

export class GbLeaves extends GbBase {
  getUrls() {
    return {
      leaveSchedule: this.getBaseUrl() + "gb/net/claims/leave?" + this.getThaaClientId("auth"),
      getCompanyDetails:
        this.getBaseUrl() + "gb/net/company/detail?" + this.getThaaClientId("auth"),
      getleaveDetailsList:
        this.getBaseUrl() + "gb/net/claims/leave/detail?" + this.getThaaClientId("auth"),
      intermittentLeaveSchedule:
        this.getBaseUrl() +
        "gb/net/claims/leave/extension/intermittent?" +
        this.getThaaClientId("auth"),
      claimIntake: this.getBaseUrl() + "gb/net/claims-intake?" + this.getThaaClientId("auth"),
      leaveReasonApi:
        this.getBaseUrl() + "gb/net/claims-intake/covered-reason?" + this.getThaaClientId("auth"),
      getRAELeaveReducedSchedule:
        this.getBaseUrl() +
        "gb/net/claims/leave/extension/reduced-schedule?" +
        this.getThaaClientId("auth"),
      getRAELeaveContinuous:
        this.getBaseUrl() +
        "gb/net/claims/leave/extension/continuous?" +
        this.getThaaClientId("auth"),
      //this url can be change
      leavePlanBalance:
        this.getBaseUrl() + "gb/net/claims/leave/balance?" + this.getThaaClientId("auth"),
      leaveConfirmationApi:
        this.getBaseUrl() + "gb/net/claims/leave/confirmation?" + this.getThaaClientId("auth"),
      workScheduleEmployeeApi:
        this.getBaseUrl() + "gb/net/employee/work-schedule?" + this.getThaaClientId("auth"),
      workScheduleApi:
        this.getBaseUrl() + "gb/net/claims/work-schedule?" + this.getThaaClientId("auth"),
      getCIIFeatureFlags:
        this.getBaseUrl() + "gb/net/employee/rights?" + this.getThaaClientId("auth"),
      getADAClaimActivityURL:
        this.getBaseUrl() + "gb/net/claims/leave/activities?" + this.getThaaClientId("auth"),
      adaTimeline:
        this.getBaseUrl() + "gb/net/employee/ada-timeline?" + this.getThaaClientId("auth"),
    };
  }
}
